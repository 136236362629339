"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _store = require("@/api/store");
var _auth = require("@/utils/auth");
var _taoBao = _interopRequireDefault(require("./taoBao"));
var _permission = require("@/utils/permission");
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// 权限判断函数
var _default = exports.default = {
  name: 'ProductList',
  components: {
    taoBao: _taoBao.default
  },
  data: function data() {
    return {
      props: {
        children: 'child',
        label: 'name',
        value: 'id',
        emitPath: false
      },
      // roterPre: roterPre,
      headeNum: [],
      listLoading: true,
      tableData: {
        data: [],
        total: 0
      },
      tableFrom: {
        page: 1,
        limit: 20,
        cateId: '',
        keywords: '',
        type: '1'
      },
      categoryList: [],
      merCateList: [],
      objectUrl: process.env.VUE_APP_BASE_API,
      dialogVisible: false
    };
  },
  mounted: function mounted() {
    this.goodHeade();
    this.getList();
    this.getCategorySelect();
    this.checkedCities = this.$cache.local.has('goods_stroge') ? this.$cache.local.getJSON('goods_stroge') : this.checkedCities;
  },
  methods: {
    checkPermi: _permission.checkPermi,
    handleRestore: function handleRestore(id) {
      var _this = this;
      this.$modalSure("恢复商品").then(function () {
        (0, _store.restoreApi)(id).then(function (res) {
          _this.$message.success('操作成功');
          _this.goodHeade();
          _this.getList();
        });
      });
    },
    seachList: function seachList() {
      this.tableFrom.page = 1;
      this.getList();
    },
    handleClose: function handleClose() {
      this.dialogVisible = false;
    },
    handleCloseMod: function handleCloseMod(item) {
      this.dialogVisible = item;
      this.goodHeade();
      this.getList();
    },
    // 复制
    onCopy: function onCopy() {
      this.dialogVisible = true;
    },
    // 导出
    exports: function exports() {
      (0, _store.productExcelApi)({
        cateId: this.tableFrom.cateId,
        keywords: this.tableFrom.keywords,
        type: this.tableFrom.type
      }).then(function (res) {
        window.location.href = res.fileName;
      });
    },
    // 获取商品表单头数量
    goodHeade: function goodHeade() {
      var _this2 = this;
      (0, _store.productHeadersApi)().then(function (res) {
        _this2.headeNum = res;
      }).catch(function (res) {
        _this2.$message.error(res.message);
      });
    },
    // 商户分类；
    getCategorySelect: function getCategorySelect() {
      var _this3 = this;
      (0, _store.categoryApi)({
        status: -1,
        type: 1
      }).then(function (res) {
        _this3.merCateList = res;
      }).catch(function (res) {
        _this3.$message.error(res.message);
      });
    },
    // 列表
    getList: function getList() {
      var _this4 = this;
      this.listLoading = true;
      (0, _store.productLstApi)(this.tableFrom).then(function (res) {
        _this4.tableData.data = res.list;
        _this4.tableData.total = res.total;
        _this4.listLoading = false;
      }).catch(function (res) {
        _this4.listLoading = false;
        _this4.$message.error(res.message);
      });
    },
    pageChange: function pageChange(page) {
      this.tableFrom.page = page;
      this.getList();
    },
    handleSizeChange: function handleSizeChange(val) {
      this.tableFrom.limit = val;
      this.getList();
    },
    // 删除
    handleDelete: function handleDelete(id, type) {
      var _this5 = this;
      this.$modalSure("\u5220\u9664 id \u4E3A ".concat(id, " \u7684\u5546\u54C1")).then(function () {
        var deleteFlag = type == 5 ? 'delete' : 'recycle';
        (0, _store.productDeleteApi)(id, deleteFlag).then(function () {
          _this5.$message.success('删除成功');
          _this5.getList();
          _this5.goodHeade();
        });
      });
    },
    onchangeIsShow: function onchangeIsShow(row) {
      var _this6 = this;
      row.isShow ? (0, _store.putOnShellApi)(row.id).then(function () {
        _this6.$message.success('上架成功');
        _this6.getList();
        _this6.goodHeade();
      }).catch(function () {
        row.isShow = !row.isShow;
      }) : (0, _store.offShellApi)(row.id).then(function () {
        _this6.$message.success('下架成功');
        _this6.getList();
        _this6.goodHeade();
      }).catch(function () {
        row.isShow = !row.isShow;
      });
    }
  }
};